import React from 'react';
import slide_image1 from '../assets/media/general/getaqpoute-banner.jpg';
import slide_image1_mob from '../assets/media/general/getaquote-banner-mb.jpg';
import alertIcon from '../assets/media/general/alert-icon.png';
import plogo1 from '../assets/media/components/infogarphic/plogo-1.png';
import plogo2 from '../assets/media/components/infogarphic/plogo-2.png';
import plogo3 from '../assets/media/components/infogarphic/plogo-3.png';
import plogo4 from '../assets/media/components/infogarphic/plogo-4.png';
import plogo5 from '../assets/media/components/infogarphic/plogo-5.png';
export class Quote extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        return (
            <>
                <div class="main-slider main-slider-1">
                    <div class="slider-pro" id="main-slider" data-slider-width="100%" data-slider-height="520px" data-slider-arrows="false" data-slider-buttons="false">
                        <div class="sp-slides">
                            <div class="sp-slide">
                                <img class="sp-image forDesktop" src={slide_image1} alt="slider" />
                                <img class="sp-image forMobile innerB" src={slide_image1_mob} alt="slider" />
                                <div class="main-slider__wrap sp-layer" data-width="" data-position="centerLeft" data-horizontal="62%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="1200" data-hide-delay="400">
                                    <div class="main-slider__title">Parthenium
                                    <span class="main-slider__label bg-primary">Parthenium</span>
                                    </div>
                                    <div class="main-slider__subtitle2">LET’S GET STARTED</div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="section-default_top getaQuote">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-5">
                                <h2 class="ui-title-block">Make of Vehicle</h2>
                                <div class="ui-subtitle-block-3">Enter the make of the vehicle</div>
                                <div class="ui-subtitle-block">(Ford, Toyota, etc.- Look at the image)</div>
                                <div class="ui-decor"></div>
                                <form>
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Select a make" />
                                        <div class="input-group-append">
                                            <button class="btn btn-secondary" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <select class="custom-select" size="8">
                                        <option selected>Open this select menu</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </form>
                                <a class="btn btn-primary btn-lg" href="getaquote.html">Previous</a>
                                <a class="btn btn-primary btn-lg" href="getaquote.html">Next</a>
                            </div>
                            <div class="col-md-4 mt150">
                                <h3>Get started <span>today!</span></h3>
                                <p><strong>Click next to proceed to schedule you service request and we'll be on our way!</strong></p>
                                <div class="cta"><img src={alertIcon} alt="" />Emergency? Click to Call <span>1 (844) 438-8444</span></div>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="section-default_btm">
                    <div class="container">
                        <div class="row align-items-center justify-content-center">
                            <div class="mtb-200 wow fadeInDown">
                                <img src={plogo1} alt="" />
                                <img src={plogo2} alt="" />
                                <img src={plogo3} alt="" />
                                <img src={plogo4} alt="" />
                                <img src={plogo5} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}


export default Quote;