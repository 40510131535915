import React from 'react';
import { Link } from 'react-router-dom';
import slide_image1 from '../assets/media/components/b-main-slider/slide-img-1.jpg';
import slide_image1_mob from '../assets/media/components/b-main-slider/home-banner-mb.jpg';
import plogo1 from '../assets/media/components/infogarphic/plogo-1.png';
import plogo2 from '../assets/media/components/infogarphic/plogo-2.png';
import plogo3 from '../assets/media/components/infogarphic/plogo-3.png';
import plogo4 from '../assets/media/components/infogarphic/plogo-4.png';
import plogo5 from '../assets/media/components/infogarphic/plogo-5.png';
import tellusaboutyourride from '../assets/media/components/infogarphic/tellusaboutyourride.png';
import receiveyourofferinstantly from '../assets/media/components/infogarphic/receiveyourofferinstantly.png';
import wewillcometoyou from '../assets/media/components/infogarphic/wewillcometoyou.png';
import getpaidonthespot from '../assets/media/components/infogarphic/getpaidonthespot.png';
import nomembershiprequired from '../assets/media/general/no-membership-required.png';
import prescreenedcertified from '../assets/media/general/pre-screened-certified.png';
import sellingyourcar from '../assets/media/general/selling-your-car.png';
import Owl from '../components/layouts/Owl';
import $ from 'jquery';
//Calling WOWjs
import WOW from 'wowjs';
export class Home extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }

    render() {
        if ($(window).width() > 300) {
            new WOW.WOW({
                boxClass: 'wow',
                animateClass: 'animated',
                offset: 0,
                mobile: false,
                live: true
            }).init();
        }
        return (
            <>
                <div className="main-slider main-slider-1">
                    <div className="sp-slides">
                        <div className="sp-slide">
                            <img className="sp-image forDesktop" src={slide_image1} alt="slider" />
                            <img className="sp-image forMobile" src={slide_image1_mob} alt="slider" />
                            <div className="main-slider__wrap sp-layer" data-width="" data-position="centerLeft" data-horizontal="62%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="1200" data-hide-delay="400">
                                <div className="main-slider__title">Fast - Easy - Reliable
                                    <span className="main-slider__label bg-primary">Parthenium</span>
                                </div>
                                <div className="main-slider__subtitle">Recieve Your Quote</div>
                                <div className="main-slider__subtitle2">In Minutes We Pay Cash</div>
                                <a className="main-slider__btn btn btn-red mmb btn-lg" href={void(0)}>
                                    <span className="main-slider__btn-label"><i className="fa  fa-play-circle-o "></i> Learn more</span>
                                </a>
                                <a className="main-slider__btn btn btn-primary btn-lg" href="getaquote.html">Sell Your Car</a>
                            </div>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row align-items-center justify-content-center hw-head">
                                <div className="col-12 text-center full">
                                    <div className="mtb-200 wow fadeInDown">
                                        <img src={plogo1} />
                                        <img src={plogo2} />
                                        <img src={plogo3} />
                                        <img src={plogo4} />
                                        <img src={plogo5} />
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-center hw-head">
                                <div className="col-12 text-center full">
                                    <h2 className="mt-110 mainHead wow fadeInUp">How It Works</h2>
                                    <p className="mainPra wow fadeInDown">Tempor incididunt labore dolore magna aliqua sed ipsum</p>
                                    <div className="ui-decor wow fadeInUp"></div>
                                </div>
                            </div>
                            <div className="row timeline">
                                <div className="col-2">
                                    <div className="corner right-bottom"></div>
                                </div>
                                <div className="col-3">
                                    <hr />
                                </div>
                                <div className="col-2">
                                    <div className="corner top-left"></div>
                                </div>
                            </div>
                            <div className="row align-items-center how-it-works">
                                <div className="col-2 text-center full-1">
                                    <div className="circle wow fadeInUp">1</div>
                                </div>
                                <div className="col-12 col-sm-4 padd-tb-25 wow fadeInDown">
                                    <h3 className="subHead">Tell us about your ride.</h3>
                                    <p>Give us a quick rundown of your car. What's the year, make, model? Let us know where it's holding up. All these details help us give your best offer.</p>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <img src={tellusaboutyourride} className=" wow fadeInRight" />
                                </div>
                            </div>
                            <div className="row timeline">
                                <div className="col-2">
                                    <div className="corner top-right"></div>
                                </div>
                                <div className="col-8">
                                    <hr />
                                </div>
                                <div className="col-2">
                                    <div className="corner left-bottom"></div>
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-end how-it-works">
                                <div className="col-12 col-sm-6">
                                    <img src={receiveyourofferinstantly} className="wow fadeInLeft" />
                                </div>
                                <div className="col-12 col-sm-4 text-right padd-tb-25 wow fadeInDown">
                                    <h3 className="subHead">Receive your offer instantly.</h3>
                                    <p>We'll make you an offer straight await! Check it out, then take it or leave it. There are zero obligations. It's really as simple as answering a few questions about your car and you're done.</p>
                                </div>
                                <div className="col-2 text-center full">
                                    <div className="circle wow fadeInUp">2</div>
                                </div>
                            </div>
                            <div className="row timeline">
                                <div className="col-2">
                                    <div className="corner right-bottom"></div>
                                </div>
                                <div className="col-8">
                                    <hr />
                                </div>
                                <div className="col-2">
                                    <div className="corner top-left"></div>
                                </div>
                            </div>
                            <div className="row align-items-center how-it-works">
                                <div className="col-2 text-center full-1">
                                    <div className="circle wow fadeInUp">3</div>
                                </div>
                                <div className="col-12 col-sm-4 padd-tb-25 wow fadeInDown">
                                    <h3 className="subHead">We'll come to you.</h3>
                                    <p>Choose a convenient date and time for pick-up. During our visit, we'll inspect your car to verify its condition, look over your title to make sure it's signed and notarized (if needed) in agreement with state requirements.</p>
                                </div>
                                <div className="col-12 col-sm-6">
                                    <img src={wewillcometoyou} className="wow fadeInRight" />
                                </div>
                            </div>
                            <div className="row timeline">
                                <div className="col-2">
                                    <div className="corner top-right"></div>
                                </div>
                                <div className="col-8">
                                    <hr />
                                </div>
                                <div className="col-2">
                                    <div className="corner left-bottom"></div>
                                </div>
                            </div>
                            <div className="row align-items-center justify-content-end how-it-works">
                                <div className="col-12 col-sm-6">
                                    <img src={getpaidonthespot} className="wow fadeInLeft" />
                                </div>
                                <div className="col-12 col-sm-4 text-right padd-tb-25 wow fadeInDown">
                                    <h3 className="subHead">Get paid on the spot.</h3>
                                    <p>Once everything checks out, we'll hand over cash, load up your car, and off we go! To make sure that everything goes off without a hitch, be sure to answer the condition questions as accurately as possible, and prep your documents carefully.</p>
                                </div>
                                <div className="col-2 text-center full">
                                    <div className="circle wow fadeInUp">4</div>
                                </div>
                            </div>
                            <div className="row timeline">
                                <div className="col-5 offset-2 col-md-3 offset-md-4">
                                    <div className="corner2">
                                        <Link className="btn btn-primary w100 wow fadeInRight" to="/quote">Get a Quote</Link>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <hr />
                                </div>
                                <div className="col-2">
                                    <div className="corner top-left"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-default_top">
                    <div className="info-group block-table block-table_md">
                        <div className="info-group__section col-lg-8  whyusContentChild">
                            <section className="b-info_mod-a parallax">
                                <div className="area-bg__inner wow fadeInDown">
                                    <h2 className="ui-title-block">Why Us?</h2>
                                    <div className="ui-subtitle-block">Tempor incididunt duis labore dolore magna aliqua sed ipsum</div>
                                    <div className="ui-decor"></div>
                                </div>
                            </section>
                            <section className="b-info b-info_mod-a area-bg_prim parallax wow fadeInLeft red-bg">
                                <div className="area-bg__inner">
                                    <div className="b-about-main">
                                        <div className="b-about-main__title cf">Parthenium, also known as "Angels on the Road"</div>
                                        <div className="b-about-main__subtitle">When you need to sell your vehicle, Parthenium is who you need to call!</div>
                                        <p>With our “Near Me Service” selling your auto has never been easier. We provide an effortless process from getting a quote to picking up your vehicle. “Near Me Service,” means we come to your location making it very convenient for the seller. From Miami-Dade, Broward, and Palm Beach Counties getting a cash offer from Parthenium is easy, fast, and reliable. Get your offer now!</p>
                                        <div className="b-about-main__btns">
                                            <a className="btn btn-dark" href={void(0)}>Our partners</a>
                                            <a className="btn btn-white" href={void(0)}>learn more</a>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="info-group__section col-lg-4">
                            <section className="b-info_mod-b parallax wow fadeInRight">
                                <div className="area-bg__inner">
                                    <ul className="list-unstyled">
                                        <li className="b-about-list__item">
                                            <i className="b-about-list__icon flaticon-travel"></i>
                                            <div className="b-about-list__inner">
                                                <div className="b-about-list__title">10 Years in Business</div>
                                                <div className="b-about-list__info">Parthenium is nisi aliquip ea consequat duis velit esse cillum dolore fugiat nulla pariatur excepteur sint occaecat.</div>
                                                <div className="ui-decor-2"></div>
                                            </div>
                                        </li>
                                        <li className="b-about-list__item">
                                            <i className="b-about-list__icon flaticon-handshake"></i>
                                            <div className="b-about-list__inner">
                                                <div className="b-about-list__title">Trusted by Auto Buyers</div>
                                                <div className="b-about-list__info">Parthenium is nisi aliquip ea consequat duis velit esse cillum dolore fugiat nulla pariatur excepteur sint occaecat.</div>
                                                <div className="ui-decor-2"></div>
                                            </div>
                                        </li>
                                        <li className="b-about-list__item">
                                            <i className="b-about-list__icon flaticon-transport"></i>
                                            <div className="b-about-list__inner">
                                                <div className="b-about-list__title">Affordable Auto Prices</div>
                                                <div className="b-about-list__info">Parthenium is nisi aliquip ea consequat duis velit esse cillum dolore fugiat nulla pariatur excepteur sint occaecat.</div>
                                                <div className="ui-decor-2"></div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </section>
                        </div>
                    </div>
                </section>
                <section className="section-default">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="text-center wow fadeInUp">
                                    <h2 className="ui-title-block">Vehicles We’ve Purchased</h2>
                                    <div className="ui-subtitle-block">Tempor incididunt labore dolore magna alique</div>
                                    <div className="ui-decor"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Owl />

                </section>
                <section className="section-default_btm">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-advantages-1">
                                    <section className="b-advantages-1 wow fadeInRight">
                                        <img src={nomembershiprequired} />
                                        <h3 className="b-advantages-1__title">No Membership Required</h3>
                                        <div className="b-advantages-1__info">Parthenium is nisi aliquip ex consequat duis velit esse cillum dolore fugiat nulla pariatur excepteur sint occaecat.</div>
                                        <span className="ui-decor-2"></span>
                                    </section>
                                    <section className="b-advantages-1 active wow fadeInUp">
                                        <img src={prescreenedcertified} />
                                        <h3 className="b-advantages-1__title">We Offers Lower Cars Prices</h3>
                                        <div className="b-advantages-1__info">Pre-screened certified, professional, and licensed drivers available to help you with your roadside assistance.</div>
                                        <span className="ui-decor-2"></span>
                                    </section>
                                    <section className="b-advantages-1 wow fadeInRight">
                                        <img src={sellingyourcar} />
                                        <h3 className="b-advantages-1__title">Multipoint Safety Checks</h3>
                                        <div className="b-advantages-1__info">Parthenium is nisi aliquip ex consequat duis velit esse cillum dolore fugiat nulla pariatur excepteur sint occaecat.</div>
                                        <span className="ui-decor-2"></span>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}
export default Home;