import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import logo_login from './../assets/media/general/logo-login.png';
import './../assets/css/login.css';

class Login extends React.Component {

  render() {


    return (
      <Router>
        <div className="App">
        
        <div class="form-body no-side">
            <div class="row">
                <div class="form-holder">
                    
                    <div class="form-content">
                        <div class="text-center logo">
                            <a href="index.html">
                                <img src={logo_login} alt=""/>
                            </a>
                        </div>
                        <div class="form-items">
                            <div class="spacer"></div>
                            <h3>Log in</h3>
                            <p>Please enter the details of your session</p>
                            <div class="ui-decor"></div>
                            <form class="form-row">
                                <div class="col-md-12">
                                    <input type="email" class="form-control" placeholder="Enter Email Address"/>
                                </div>
                                <div class="col-md-12">
                                    <input type="password" class="form-control" placeholder="Enter Password"/>
                                </div>
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-primary">Log-In</button>
                                </div>
                            </form>
                            <div class="spacer"></div>
                        </div>
                        <div class="other-links text-center" style={padd}>
                            Copyrights © 2020 <span>Parthenium</span>  All rights reserved. <a href="#">Privacy Policy </a> | <a href="#">Terms & Conditions </a>
                            <div class="socailArea">
                                <a href="#"><i class="fa fa-twitter"></i></a>
                                <a href="#"><i class="fa fa-facebook-f"></i></a>
                                <a href="#"><i class="fa fa-instagram"></i></a>
                                <a href="#"><i class="fa fa-youtube-play"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
      </Router>
    );
  }
}
const padd = {
    padding: '20px'
}
export default Login;
