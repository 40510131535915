import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Login from './components/Login';
import './assets/css/master.css';
import 'bootstrap/dist/js/bootstrap.js'
function Greeting() {
  const isLoggedIn = window.location.href.split('/')[3];
  console.log(isLoggedIn);
  if (isLoggedIn == 'login') {
    return <Login />;
  } else {
    return <App />;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Greeting />
  </React.StrictMode>,
  document.getElementById('root')
);

/* // If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(); */
