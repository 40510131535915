import React from 'react';
import { Link } from 'react-router-dom';
import logo_dark from '../../assets/media/general/logo-dark.png';
import logo from '../../assets/media/general/logo.png';

export class Header extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }

    render() {
        return (
            
            <header className="header header-boxed-width navbar-fixed-top fixed-top header-background-white header-color-black header-topbar-dark header-logo-black header-topbarbox-1-left header-topbarbox-2-right header-navibox-1-left header-navibox-2-right header-navibox-3-right header-navibox-4-right">
                <div className="container-boxed-width">
                    <div className="top-bar">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-9 justify-content-start header-topbarbox-1 text-left">
                                    <ul>
                                        <li><i className="icon fa fa-clock-o"></i> Mon - Fri : 09:00am to 06:00pm</li>
                                        <li><i className="icon fa fa-phone"></i><a href={void(0)}>1 (844) 438-8444</a></li>
                                        <li><i className="icon fa fa-envelope-o"></i><a href="mailto:info@parthenium.com">info@parthenium.com</a></li>
                                    </ul>
                                </div>
                                <div className="col-3 justify-content-end header-topbarbox-2 text-right">
                                    <ul className="social-links">
                                        <li><a href="/" target="_blank"><i className="social_icons fa fa-twitter"></i></a></li>
                                        <li><a href="/" target="_blank"><i className="social_icons fa fa-facebook"></i></a></li>
                                        <li className="li-last"><a href="/" target="_blank"><i className="social_icons fa fa-instagram"></i></a></li>
                                        <li><a href="/" target="_blank"><i className="social_icons fa fa-youtube-play"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <nav className="navbar navbar-expand-lg navbar-light" id="nav">
                        <a className="navbar-brand scroll" href="index.html">
                            <img className="normal-logo img-responsive" src={logo_dark} alt="logo" />
                            <img className="scroll-logo hidden-xs img-responsive" src={logo} alt="logo" />
                        </a>
                        <button className="navbar-toggler menu-mobile-button js-toggle-mobile-slidebar toggle-menu-button" type="button" data-toggle="collapse">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end">
                            <ul className="navbar-nav yamm main-menu nav">
                                <li className="nav-item active">
                                    <Link className="nav-link" to="/">Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/about" >About Us</Link>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#contactUs">Contact Us</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/login">Login</a>
                                </li>

                            </ul>
                            <a className="btn btn-dark m-right-5" href={void(0)}>My Offers (0)</a>
                            <Link className="btn btn-primary" to="/quote">Get an Offer</Link>
                            <ul className="nav navbar-nav mr-m1 hidden-xs clearfix vcenter">
                                <li><a className="btn_header_search" href={void(0)}><i className="icon fa fa-search"></i></a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}


export default Header;