import React, { Component, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import vehiclesimg1 from '../../assets/media/components/b-goods/vehicles-img-1.jpg';
import vehiclesimg2 from '../../assets/media/components/b-goods/vehicles-img-2.jpg';
import vehiclesimg3 from '../../assets/media/components/b-goods/vehicles-img-3.jpg';
import vehiclesimg4 from '../../assets/media/components/b-goods/vehicles-img-1.jpg';
import vehiclesimg5 from '../../assets/media/components/b-goods/vehicles-img-1.jpg';
import OwlCarousel, { Options } from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


export class Owl extends React.Component {
    
    render() {
        return (
            <OwlCarousel
                className="featured-carousel owl-theme"
                loop={false}
                margin={10}
                items={5}
                nav={true}
                dots={false}
                {...State.options}
            >
                <div className="b-goods-feat">
                    <div className="b-goods-feat__img">
                        <img className="img-responsive" src={vehiclesimg1} alt="foto" /><span className="b-goods-feat__label">$45,000<span className="b-goods-feat__label_msrp">MSRP $27,000</span></span>
                    </div>
                    <ul className="b-goods-feat__desc list-unstyled">
                        <li className="b-goods-feat__desc-item">35,000 mi</li>
                        <li className="b-goods-feat__desc-item">Model: 2017</li>
                        <li className="b-goods-feat__desc-item">Auto</li>
                        <li className="b-goods-feat__desc-item">320 hp</li>
                    </ul>
                    <h3 className="b-goods-feat__name"><a href="#">Lexus GX 490i Hybird</a></h3>
                    <div className="b-goods-feat__info">Duis aute irure reprehender voluptate velit ese acium fugiat nulla pariatur excepteur ipsum.</div>
                </div>
                <div className="b-goods-feat">
                    <div className="b-goods-feat__img">
                        <img className="img-responsive" src={vehiclesimg2} alt="foto" /><span className="b-goods-feat__label">$45,000<span className="b-goods-feat__label_msrp">MSRP $27,000</span></span>
                    </div>
                    <ul className="b-goods-feat__desc list-unstyled">
                        <li className="b-goods-feat__desc-item">35,000 mi</li>
                        <li className="b-goods-feat__desc-item">Model: 2017</li>
                        <li className="b-goods-feat__desc-item">Auto</li>
                        <li className="b-goods-feat__desc-item">320 hp</li>
                    </ul>
                    <h3 className="b-goods-feat__name"><a href="#">Lexus GX 490i Hybird</a></h3>
                    <div className="b-goods-feat__info">Duis aute irure reprehender voluptate velit ese acium fugiat nulla pariatur excepteur ipsum.</div>
                </div>
                <div className="b-goods-feat">
                    <div className="b-goods-feat__img">
                        <img className="img-responsive" src={vehiclesimg3} alt="foto" /><span className="b-goods-feat__label">$45,000<span className="b-goods-feat__label_msrp">MSRP $27,000</span></span>
                    </div>
                    <ul className="b-goods-feat__desc list-unstyled">
                        <li className="b-goods-feat__desc-item">35,000 mi</li>
                        <li className="b-goods-feat__desc-item">Model: 2017</li>
                        <li className="b-goods-feat__desc-item">Auto</li>
                        <li className="b-goods-feat__desc-item">320 hp</li>
                    </ul>
                    <h3 className="b-goods-feat__name"><a href="#">Toyota Avalon TX</a></h3>
                    <div className="b-goods-feat__info">Duis aute irure reprehender voluptate velit ese acium fugiat nulla pariatur excepteur ipsum.</div>
                </div>
                <div className="b-goods-feat">
                    <div className="b-goods-feat__img">
                        <img className="img-responsive" src={vehiclesimg4} alt="foto" /><span className="b-goods-feat__label">$45,000<span className="b-goods-feat__label_msrp">MSRP $27,000</span></span>
                    </div>
                    <ul className="b-goods-feat__desc list-unstyled">
                        <li className="b-goods-feat__desc-item">35,000 mi</li>
                        <li className="b-goods-feat__desc-item">Model: 2017</li>
                        <li className="b-goods-feat__desc-item">Auto</li>
                        <li className="b-goods-feat__desc-item">320 hp</li>
                    </ul>
                    <h3 className="b-goods-feat__name"><a href="#">Lexus GX 490i Hybird</a></h3>
                    <div className="b-goods-feat__info">Duis aute irure reprehender voluptate velit ese acium fugiat nulla pariatur excepteur ipsum.</div>
                </div>
                <div className="b-goods-feat">
                    <div className="b-goods-feat__img">
                        <img className="img-responsive" src={vehiclesimg5} alt="foto" /><span className="b-goods-feat__label">$45,000<span className="b-goods-feat__label_msrp">MSRP $27,000</span></span>
                    </div>
                    <ul className="b-goods-feat__desc list-unstyled">
                        <li className="b-goods-feat__desc-item">35,000 mi</li>
                        <li className="b-goods-feat__desc-item">Model: 2017</li>
                        <li className="b-goods-feat__desc-item">Auto</li>
                        <li className="b-goods-feat__desc-item">320 hp</li>
                    </ul>
                    <h3 className="b-goods-feat__name"><a href="#">Lexus GX 490i Hybird</a></h3>
                    <div className="b-goods-feat__info">Duis aute irure reprehender voluptate velit ese acium fugiat nulla pariatur excepteur ipsum.</div>
                </div>
                <div className="b-goods-feat">
                    <div className="b-goods-feat__img">
                        <img className="img-responsive" src={vehiclesimg1} alt="foto" /><span className="b-goods-feat__label">$45,000<span className="b-goods-feat__label_msrp">MSRP $27,000</span></span>
                    </div>
                    <ul className="b-goods-feat__desc list-unstyled">
                        <li className="b-goods-feat__desc-item">35,000 mi</li>
                        <li className="b-goods-feat__desc-item">Model: 2017</li>
                        <li className="b-goods-feat__desc-item">Auto</li>
                        <li className="b-goods-feat__desc-item">320 hp</li>
                    </ul>
                    <h3 className="b-goods-feat__name"><a href="#">Lexus GX 490i Hybird</a></h3>
                    <div className="b-goods-feat__info">Duis aute irure reprehender voluptate velit ese acium fugiat nulla pariatur excepteur ipsum.</div>
                </div>
                <div className="b-goods-feat">
                    <div className="b-goods-feat__img">
                        <img className="img-responsive" src={vehiclesimg2} alt="foto" /><span className="b-goods-feat__label">$45,000<span className="b-goods-feat__label_msrp">MSRP $27,000</span></span>
                    </div>
                    <ul className="b-goods-feat__desc list-unstyled">
                        <li className="b-goods-feat__desc-item">35,000 mi</li>
                        <li className="b-goods-feat__desc-item">Model: 2017</li>
                        <li className="b-goods-feat__desc-item">Auto</li>
                        <li className="b-goods-feat__desc-item">320 hp</li>
                    </ul>
                    <h3 className="b-goods-feat__name"><a href="#">Lexus GX 490i Hybird</a></h3>
                    <div className="b-goods-feat__info">Duis aute irure reprehender voluptate velit ese acium fugiat nulla pariatur excepteur ipsum.</div>
                </div>
                <div className="b-goods-feat">
                    <div className="b-goods-feat__img">
                        <img className="img-responsive" src={vehiclesimg3} alt="foto" /><span className="b-goods-feat__label">$45,000<span className="b-goods-feat__label_msrp">MSRP $27,000</span></span>
                    </div>
                    <ul className="b-goods-feat__desc list-unstyled">
                        <li className="b-goods-feat__desc-item">35,000 mi</li>
                        <li className="b-goods-feat__desc-item">Model: 2017</li>
                        <li className="b-goods-feat__desc-item">Auto</li>
                        <li className="b-goods-feat__desc-item">320 hp</li>
                    </ul>
                    <h3 className="b-goods-feat__name"><a href="#">Toyota Avalon TX</a></h3>
                    <div className="b-goods-feat__info">Duis aute irure reprehender voluptate velit ese acium fugiat nulla pariatur excepteur ipsum.</div>
                </div>
                <div className="b-goods-feat">
                    <div className="b-goods-feat__img">
                        <img className="img-responsive" src={vehiclesimg5} alt="foto" /><span className="b-goods-feat__label">$45,000<span className="b-goods-feat__label_msrp">MSRP $27,000</span></span>
                    </div>
                    <ul className="b-goods-feat__desc list-unstyled">
                        <li className="b-goods-feat__desc-item">35,000 mi</li>
                        <li className="b-goods-feat__desc-item">Model: 2017</li>
                        <li className="b-goods-feat__desc-item">Auto</li>
                        <li className="b-goods-feat__desc-item">320 hp</li>
                    </ul>
                    <h3 className="b-goods-feat__name"><a href="#">Lexus GX 490i Hybird</a></h3>
                    <div className="b-goods-feat__info">Duis aute irure reprehender voluptate velit ese acium fugiat nulla pariatur excepteur ipsum.</div>
                </div>
                <div className="b-goods-feat">
                    <div className="b-goods-feat__img">
                        <img className="img-responsive" src={vehiclesimg1} alt="foto" /><span className="b-goods-feat__label">$45,000<span className="b-goods-feat__label_msrp">MSRP $27,000</span></span>
                    </div>
                    <ul className="b-goods-feat__desc list-unstyled">
                        <li className="b-goods-feat__desc-item">35,000 mi</li>
                        <li className="b-goods-feat__desc-item">Model: 2017</li>
                        <li className="b-goods-feat__desc-item">Auto</li>
                        <li className="b-goods-feat__desc-item">320 hp</li>
                    </ul>
                    <h3 className="b-goods-feat__name"><a href="#">Lexus GX 490i Hybird</a></h3>
                    <div className="b-goods-feat__info">Duis aute irure reprehender voluptate velit ese acium fugiat nulla pariatur excepteur ipsum.</div>
                </div>
            </OwlCarousel>

        );
    }
}
const State = {
    options: {
        loop: true,
        margin: 10,
        nav:true,
        responsive:{
            0: {
                items: 1,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 5,
            },
        },
    },
}

export default Owl;