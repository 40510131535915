import React from 'react';
import slide_image1 from '../assets/media/general/about-banner.jpg';
import slide_imagemb from '../assets/media/general/about-banner-mb.jpg';
import selling_your_car from '../assets/media/general/selling-your-car-img.png';
import plogo1 from '../assets/media/components/infogarphic/plogo-1.png';
import plogo2 from '../assets/media/components/infogarphic/plogo-2.png';
import plogo3 from '../assets/media/components/infogarphic/plogo-3.png';
import plogo4 from '../assets/media/components/infogarphic/plogo-4.png';
import plogo5 from '../assets/media/components/infogarphic/plogo-5.png';
import Owl from '../components/layouts/Owl';
import $ from 'jquery';
//Calling WOWjs
import WOW from 'wowjs';
export class About extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0)
      }
    render() {
        if ($(window).width() > 300) {
            new WOW.WOW({
                boxClass: 'wow',
                animateClass: 'animated',
                offset: 0,
                mobile: false,
                live: true
            }).init();
        }
        return (
            <>
                <div className="main-slider main-slider-1">
                    <div className="slider-pro" id="main-slider" data-slider-width="100%" data-slider-height="520px" data-slider-arrows="false" data-slider-buttons="false">
                        <div className="sp-slides">
                            <div className="sp-slide">
                                <img className="sp-image forDesktop" src={slide_image1} alt="slider" />
                                <img className="sp-image forMobile innerB" src={slide_imagemb} alt="slider" />
                                <div className="main-slider__wrap sp-layer" data-width="" data-position="centerLeft" data-horizontal="62%" data-show-transition="left" data-hide-transition="left" data-show-duration="2000" data-show-delay="1200" data-hide-delay="400">
                                    <div className="main-slider__title">About Parthenium
                                    <span className="main-slider__label bg-primary">Parthenium</span>
                                    </div>
                                    <div className="main-slider__subtitle">Angels on the Road</div>
                                    <a className="main-slider__btn btn btn-primary btn-lg" href="getaquote.html">Sell Your Car</a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="section-default_top sellingYourCar">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <h2 className="ui-title-block">Selling Your Car</h2>
                                <div className="ui-subtitle-block-2">Has Never Been Easier!</div>
                                <div className="ui-decor"></div>
                                <p>Parthenium, also known as "Angels on the Road" is South Florida’s premier auto buyers. We buy all types of vehicles in Miami-Dade, Broward, and Palm Beach Counties. When you need to sell your vehicle, Parthenium is who you need to call!</p>
                                <p>We are dedicated on helping anyone who wants or needs to sell their auto. We buy junk or used cars in any condition. Typically, these are cars that have been in a car accident and were totaled, cars that have been abandon but still working, old non-operational cars, or car owners that no longer want to maintain or keep their cars in whatever condition the cars may be in. We at Parthenium offer a fair price for these types of cars and make the entire process as effortless as possible for the seller. We have a state-of-the-art system that can provide the seller with an all-cash offer in minutes. All we need is the car's condition and location of pickup. If the offer is accepted, the seller provides the date, time, and location and we arrange the pickup and pay cash on the spot. And with our “Near Me Service” it’s even more convenient for the seller, as we come to any location you prefer. This entire process can be done from the comfort of your home or any location, and it’s a no-obligation offer, so it’s entirely up to the seller to proceed.  It’s easy, fast, and reliable just contact us and get paid.</p>
                                <p>We encourage you to browse our website at www.Partheinum.com to learn more. Also, feel free to give us a call to get your free no commitment CASH offer in as simple as 1, 2, 3</p>
                                <p>We also encourage you to help us support our friends at Hope for the Warriors Foundation, for the men and women that have serviced our country by donating your car. Thank you.</p>
                            </div>
                            <div className="col-md-7">
                                <img className="img-responsive sYc_img" src={selling_your_car} alt="" />
                                <div className="col-12 text-right">
                                    <h3>Get started <span>today!</span></h3>
                                    <a className="btn btn-primary btn-lg" href="getaquote.html">Sell Your Car</a>
                                </div>
                            </div>
                        </div>
                        <div className="row section-default_top">
                            <div className="col-md-12">
                                <div className="section-advantages-1">
                                    <section className="b-advantages-1 wow fadeInRight">
                                        <img src="assets/media/general/no-membership-required.png" alt="" />
                                        <h3 className="b-advantages-1__title">No Membership Required</h3>
                                        <div className="b-advantages-1__info">Parthenium is nisi aliquip ex consequat duis velit esse cillum dolore fugiat nulla pariatur excepteur sint occaecat.</div><span className="ui-decor-2"></span>
                                    </section>
                                    <section className="b-advantages-1 active wow fadeInUp">
                                        <img src="assets/media/general/pre-screened-certified.png" alt="" />
                                        <h3 className="b-advantages-1__title">We Offers Lower Cars Prices</h3>
                                        <div className="b-advantages-1__info">Pre-screened certified, professional, and licensed drivers available to help you with your roadside assistance.</div><span className="ui-decor-2"></span>
                                    </section>
                                    <section className="b-advantages-1 wow fadeInRight">
                                        <img src="assets/media/general/selling-your-car.png" alt="" />
                                        <h3 className="b-advantages-1__title">Multipoint Safety Checks</h3>
                                        <div className="b-advantages-1__info">Parthenium is nisi aliquip ex consequat duis velit esse cillum dolore fugiat nulla pariatur excepteur sint occaecat.</div><span className="ui-decor-2"></span>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <section className="section-default_top">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="text-center wow fadeInUp">
                                    <h2 className="ui-title-block">Vehicles We’ve Purchased</h2>
                                    <div className="ui-subtitle-block">Tempor incididunt labore dolore magna alique</div>
                                    <div className="ui-decor"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Owl />
                </section>
                <div className="section-default_btm">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="mtb-200 wow fadeInDown">
                                <img src={plogo1} alt="" />
                                <img src={plogo2} alt="" />
                                <img src={plogo3} alt="" />
                                <img src={plogo4} alt="" />
                                <img src={plogo5} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}


export default About;